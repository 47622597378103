/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Switch, TableCell, TextField } from "@material-ui/core";
// import Table from "@mui/material/Table";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Moment from "moment";
import client from "ApiClient";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CircularProgress, Grid, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import CustomToolbarSelect from "./customToolbarSelect";
import Details from "./details";

/* eslint-disable */
export default function Table(props) {
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [broadcasts, setBroadcasts] = useState([]);
  const [broadcastsSelected, setBroadcastSelected] = useState([]);
  const [openLog, setOpenLog] = useState(false);
  const [previewTemplate, setPreviewTemplate] = useState("");
  const [subjectPreview, setSubjectPreview] = useState("");
  const [adminSettings, setAdminSettings] = useState({});
  const [dateAt, setDateAt] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [enableAutoApprovals, setEnableAutoApprovals] = useState(false);
  const [maxAutoApprovalRecipients, setMaxAutoApprovalRecipients] = useState(500);
  const [senders, setSenders] = useState([]);
  const [selectedSender, setSelectedSenders] = useState(1);
  const handleOpenModal = () => setOpenModal(true);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "broadcasts",
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `broadcasts/GetPendingApproval`;
    await client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) !== "[{}]") setAcconuntsData(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getAdminSettings = () => {
    setIsLoading(true);
    options.method = "get";
    options.url = `broadcasts/adminSettings`;
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        setAdminSettings(response[0]);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const saveAutoApprovals = () => {
    setIsLoading(true);
    options.method = "PUT";
    options.url = `broadcasts/adminSettings`;
    options.headers = {
      "content-type": "application/json",
    };

    options.data = JSON.stringify({
      ase_id: adminSettings.ase_id,
      ase_dnsRecordDKIM: adminSettings.ase_dnsRecordDKIM,
      ase_dnsRecordSPF: adminSettings.ase_dnsRecordSPF,
      ase_logoURL: adminSettings.ase_logoURL,
      ase_stripeApiKey: adminSettings.ase_stripeApiKey,
      ase_stripeEndpointSecret: adminSettings.ase_stripeEndpointSecret,
      ase_autoApprovalEnabled: enableAutoApprovals,
      ase_autoApprovalTimeFrom: dateAt,
      ase_autoApprovalTimeTo: dateTo,
      ase_autoApprovalMaxRecipients: maxAutoApprovalRecipients,
      ase_ema_id: selectedSender,
    });

    client
      .request(options)
      .then((response) => {})
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getDateValue = (date) => {
    return Moment(date).format("L");
  };

  const pause = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/pause`;
    options.data = JSON.stringify(broadcasts);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        // setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const resume = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/resume`;
    options.data = JSON.stringify(broadcasts);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        // setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getSenders = async () => {
    options.method = "GET";
    options.url = `emailSenders`;
    await client
      .request(options)
      .then((response) => {
        setSenders(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setDateAt(Moment(adminSettings.ase_autoApprovalTimeFrom).toDate());
    setDateTo(Moment(adminSettings.ase_autoApprovalTimeTo).toDate());
    setEnableAutoApprovals(adminSettings.ase_autoApprovalEnabled);
    setMaxAutoApprovalRecipients(adminSettings.ase_autoApprovalMaxRecipients);
    setSelectedSenders(adminSettings.ase_ema_id);
  }, [adminSettings]);

  useEffect(() => {
    saveAutoApprovals();
  }, [enableAutoApprovals]);

  useEffect(() => {
    const interval = setInterval(() => {
      getData();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getAdminSettings();
    getData();
  }, [props.groupAdded]);

  useEffect(() => {
    if (!openLog) {
      setIsLoading(true);
      setAcconuntsData([]);
      setPreviewTemplate("");
      setSubjectPreview("");
      getSenders();
      getData();
    }
  }, [openLog]);

  const cancel = () => {
    setIsLoading(true);
    options.method = "POST";
    options.url = `broadcasts/cancel`;
    options.data = JSON.stringify(broadcasts);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        // setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const columns = [
    {
      label: "Name",
      name: "bro_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Recipients",
      name: "total_recipients",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Scheduled date",
      name: "bro_date",
      options: {
        filter: true,
        //   customBodyRender: (value) => getDateValue(value),
      },
    },
    {
      label: " ",
      name: "bro_id",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setBroadcasts(acconuntsData[dataIndex]);
                  setOpenLog(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="View details">
                  <VisibilityIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const customToolbarSelect = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "you don't have any broadcast",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "broadcast/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "broadcasts.csv", separator: "," },
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      let selected = [];
      allRowsSelected.map((row) => {
        selected.push(acconuntsData[row.dataIndex]);
      });
      setBroadcastSelected(selected);
    },
    customToolbarSelect: customToolbarSelect,
    options: {
      setTableProps: () => {
        return {
          // material ui v4 only
          size: "small",
        };
      },
    },
  };

  return (
    <div>
      <Details broadcast={broadcasts} openModal={openLog} closeModal={() => setOpenLog(false)} />
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <MDTypography variant="h2">
          Pending approvals
          {isLoading && (
            <CircularProgress size={30} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDTypography>
        <MDBox mt={2} display="flex">
          <Switch
            checked={enableAutoApprovals}
            onChange={(e) => {
              setEnableAutoApprovals(e.target.checked);
            }}
            label="Enable auto approvals"
          />
          <MDTypography>Enable auto approvals</MDTypography>
        </MDBox>
        <MDBox mt={2} display="flex">
          {enableAutoApprovals && (
            <>
              <MDBox style={{ marginLeft: "25px" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Select the time from"
                    value={dateAt}
                    onChange={(newValue) => {
                      setDateAt(newValue);
                    }}
                  />
                </LocalizationProvider>
              </MDBox>
              <MDBox style={{ marginLeft: "25px" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Select the time to"
                    value={dateTo}
                    onChange={(newValue) => {
                      setDateTo(newValue);
                    }}
                  />
                </LocalizationProvider>
              </MDBox>
              <MDBox style={{ marginLeft: "25px" }}>
                <MDInput
                  type="number"
                  value={maxAutoApprovalRecipients}
                  onChange={(e) => setMaxAutoApprovalRecipients(e.target.value)}
                  label="max recipients"
                />
              </MDBox>
              <MDBox m={2}>
                <MDBox mb={2} display="flex">
                  <MDTypography variant="h6" fontWeight="medium" ml={1} mr={2}>
                    Default sender
                  </MDTypography>
                  <Select
                    disabled={isLoading}
                    onChange={(e) => setSelectedSenders(e.target.value)}
                    value={selectedSender}
                    label="selec a sender to change"
                  >
                    {senders.map((item) => (
                      <MenuItem key={item.ema_id} value={item.ema_id}>
                        {item.ema_name}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
              </MDBox>
              <MDBox style={{ marginLeft: "25px" }}>
                <MDButton color="success" onClick={(e) => saveAutoApprovals()}>
                  Save
                </MDButton>
              </MDBox>
            </>
          )}
        </MDBox>
      </MDBox>

      <Grid container p={2}>
        <Grid item xs={12}>
          <div style={{ height: "100%", width: "100%" }}>
            <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
